import * as React from "react"
import Seo from "../../components/seo"
import Layout from "../../components/layout"
import CustomHeader from "../../components/CustomHeader"
import BusinessPlatforms from "../../components/BusinessPlatforms"
import SectionTitle from "../../components/SectionTitle"
import ButtonTransparent from "../UI/ButtonTransparent"
import downloadImg from "../../images/coop.png"

function brochure () {
  return (
    <Layout>
      <Seo title="CO-OP" />
      {/* <CustomHeader title="SEIN Terminal CO-OP" /> */}
      <section id="coop" className="body-font mt-2 md:mt-10 pt-10">
                <div className="container px-4 max-w-6xl mx-auto items-center flex flex-wrap">
                  <SectionTitle
                    titleHead="CO-OP"
                    title="CO-OP"
                    spanTag="Pay as you Develop"
                    customClass="text-center md:w-full xl:w-full"
                  />
                    <div className="mt-0 mb-2 sm:mx-auto sm:w-full sm:max-w-[450px]">
                      <div className="text-center">
                        <a data-aos="fade-up" 
                            className="bg-emerald-800 text-white active:bg-emerald-400 
                                          text-sm font-bold px-7 py-4 rounded-full 
                                           hover:bg-emerald-600 hover:shadow-lg 
                                          outline-none ease-linear transition-all duration-300  
                                          mb-3 inline-block mr-2"
                                          href="https://assets.sageterminal.com/files/stp-co-op.pdf"
                            target="_blank">
                                            
                            Download SEIN Terminal CO-OP
                          </a>
                          <a data-aos="fade-up" 
                            className="bg-emerald-100 text-emerald-800 hover:text-white actactive:bg-emerald-400 
                                          text-sm font-bold px-7 py-4 rounded-full 
                                          hover:bg-emerald-600 hover:shadow-lg 
                                          outline-none ease-linear transition-all duration-300  
                                          mb-3 inline-block"
                                          href="https://calendly.com/aah-1/group-member-onboarding-training"
                            target="_blank">
                                            
                            Enroll Now
                          </a>
                        </div>
                        <div>
                          <a className="px-2 hover:opacity-80 w-80"
                            href="https://calendly.com/aah-1/group-member-onboarding-training"
                            target="_blank">
                            <img
                              className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                              src={downloadImg}
                              alt="SEIN Terminal CO-OP"
                            />
                          </a>
                        </div>
                    </div>
                </div>


      </section>
    </Layout>
    )
}

export default brochure
